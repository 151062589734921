import { graphql, useStaticQuery } from "gatsby";

const useProjectsdata = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ALL_PROJECTS {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "projets-tbwa" } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              excerpt
              fields {
                slug
              }
              frontmatter {
                title
                description
                services
                client
                logo {
                  publicURL
                }
                image {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                thumb {
                  childImageSharp {
                    fluid(maxWidth: 400, maxHeight: 300,quality:100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges;
};

export default useProjectsdata;
