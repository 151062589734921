import React from "react";
import Helmet from "react-helmet"
import useSiteProjects from "../../components/SiteProjects";
import Layout from "../../components/Layout";
import {
  HeroSection,
  UnderLine,
  Container
} from "../../components/global-style";
import projectsHero from "../../img/images/projects.jpg";
import styled from "styled-components";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const LogosGrid = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 100px;
    margin-right: 1rem;
  }
`;
const ProjectsGrid = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .logo {
    width: 100px;
  }
  .box-container{
    width: 30%;
    margin:10px;
  }
  @media(max-width:800px){
    .box-container{
    width: 100%;
  }
  }
  .box-container:hover .overlay {
    opacity: 1;
  }
  .overlay {
    min-height: 20rem;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--tbwa-overlay);
    align-items: center;
    h4 {
      max-width: 10rem;
      margin: 0 auto;
      text-align: center;
    }
    li{
      list-style: none;
      color: white;
      font-style: italic;
      font-size: 0.75rem;
      padding: 0 1rem;

    }
  }
`;
const Index = () => {
  const projectsData = useSiteProjects();

  const logos = projectsData.map(
    item => item.node.frontmatter
  );
const clients = [];

const uniqLogos = logos.map((item) => {

  if (!clients.includes(item.client)) {
    clients.push(item.client);
    return item.logo.publicURL ;
  }

}).filter(Boolean);

console.log('uniqLogos',uniqLogos);

  return (
    <Layout>
       <Helmet>
        <html lang="fr" />
        <title>Projet-TBWA \ DJAZ</title>
        <meta
          name="description"
          content="Découvrez comment nous accompagnons nos clients à créer des marques fortes à travers des projets intégrés"
        />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content="TBWA\ DJAZ" />
        <meta property="og:url" content="/http://tbwadjaz.com" />
      </Helmet>
      <HeroSection bgImage={projectsHero}>
        <Container>
          <h1>Projets</h1>
          <UnderLine />
          <LogosGrid>
            {uniqLogos.map((logo, index) => {
              return (
                <img key={index} src={logo} />
              );
            })}
          </LogosGrid>
        </Container>
      </HeroSection>
      <ProjectsGrid>
        {projectsData.map(data => {
          return (
            <div key={data.node.id} className="box-container">
              <Link to={data.node.fields.slug}>
                <BackgroundImage
                  fluid={data.node.frontmatter.image.childImageSharp.fluid}
                >
                  <div className="overlay">
                    <img
                      src={data.node.frontmatter.logo.publicURL}
                      className="logo"
                    />
                    <h4>{data.node.frontmatter.title}</h4>
                    <ul>
                      {data.node.frontmatter.services?.map((service, index) => (
                        <li key={index}>{service}</li>
                      ))}
                    </ul>
                  </div>
                </BackgroundImage>
              </Link>
            </div>
          );
        })}
      </ProjectsGrid>
    </Layout>
  );
};

export default Index;
